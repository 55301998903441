.wrap {
  display: flex;
  margin-top: 12px;
  background-color: white;
  min-height: 240px;
  & > div {
    width: calc(100% / 3 - 10px);
    // width: 100%;
    border-radius: var(--border-radius);
    border: 1px solid var(--secondary);
    box-sizing: border-box;
    padding: 0 20px 20px;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}
.pieWrap {
  display: flex;
  align-items: center;
  height: 220px;

  // height: 440px;
}
.wrapTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 20px 0;
  text-wrap: nowrap;
}

.chartContainer {
  padding: 12px 20px 20px 20px;
}

.containerTitle {
  font-family: "Inter-Medium";
  margin-right: 6px;
}
