$top: 11px;
$searchIconWidth: 94px;
$resetIconWidth: 25px;

.searchForm {
  width: var(--search-wrap-width);
  height: 52px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 30px;
  border: 1px solid var(--secondary);
  background-color: white;
  position: relative;

  form {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.actionType,
.countryWrap {
  width: 153px;
  flex: 0 1 auto;
  height: 100%;
  & > div {
    width: 100%;
    height: 100%;
  }
}

.icon {
  position: absolute;
  right: calc($searchIconWidth + var(--selectMenuHor) - 10px);
  width: $resetIconWidth;
  top: $top;
  height: calc(100% - $top * 2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &::before {
    font-size: 14px !important;
    color: var(--tip-text-color) !important;
  }
}

.asinWrap {
  position: relative;
  height: 100%;
  flex: 1 1 auto;
  box-sizing: border-box;
  border-left: 1px solid var(--secondary);

  padding-right: $searchIconWidth;

  & > div {
    width: 100%;
  }

  & > div:not(.example):not(.tipWrap):not(.recodWrap) {
    height: 100%;
  }
}

.tipWrap {
  position: relative;
  z-index: 1;

  :global(.MuiAlert-root) {
    box-sizing: border-box;
    word-break: break-word;
    max-width: max(500px, 53%);
  }
}

.canReset {
  padding-right: calc($searchIconWidth + $resetIconWidth);
}

.searchIcon {
  position: absolute;
  flex: 0 0 auto;
  // padding: $top 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $searchIconWidth;
  box-sizing: border-box;

  cursor: pointer;
  right: 0;

  &::before {
    content: "";
    position: absolute;
    width: 1px;
    background-color: var(--secondary);
    top: 0;
    left: 0;
    height: 100%;
  }
}

.example {
  position: absolute;
  z-index: 1000;
  margin-top: var(--selectMenuDistance);
  top: 100%;
  box-sizing: border-box;
  background-color: white;
  max-height: 490px;
  overflow-y: auto;
  overflow-x: hidden;

  & > li:not(:last-child) {
    height: 36px;
    padding: 0;
    padding: 0 var(--selectMenuHor);
    margin-bottom: 12px;
  }

  .imgsWrap {
    width: 36px;
    height: 36px;
    background-color: white;
    display: flex;
    justify-content: center;
    margin-right: $top;
    flex: 0 0 auto;

    & > span {
      overflow: hidden;
    }
  }
  img {
    object-fit: contain;
    height: 36px;
  }
}

.tipWrap {
  display: flex;
  margin: 0 auto;
  width: var(--search-wrap-width);
}

.asinTipTitle {
  font-size: var(--normal-16px);
  font-family: "Inter-Bold";
  font-weight: var(--bold-weight);
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 12px;
}

.asinTipContent {
  color: #ffffff;
}

.gotWrap {
  text-align: right;
}

.gotIt {
  border: none;
  display: inline-block;
  background-color: #ffffff;
  color: var(--primary);
  margin-top: 20px;
}

.asinTipTooltip {
  :global(.MuiTooltip-arrow) {
    margin-left: 20px;
    &::before {
      background-color: var(--primary);
    }
  }
}

.recodWrap {
  position: relative;

  & > div {
    width: calc(100% + 26px);
  }

  z-index: 1900;
}
