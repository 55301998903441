@import "./var";
:global(.AD_STRATEGY) {
  min-width: 1000px;
}
.wrap {
  display: flex;
  position: relative;
  background: #ffffff;

  & > div:first-child > div,
  .bubble {
    margin-bottom: $marginBottom;
    height: $height;
  }
}

:global(.sb) {
  --primary: #009f52;
  --light-primary: #cadcd2;
}

:global(.sbv) {
  --primary: #b76e22;
  --light-primary: #e7d5c7;
}

:global(.sbSbv) {
  --primary: #f7f8fa;
}
@keyframes point {
  0% {
    transform: translateX(-50%) translateY(-200%);
  }
  100% {
    transform: translateX(-50%) translateY(-100%);
  }
}

@keyframes blink {
  from {
    background-image: linear-gradient(
        to right,
        var(--primary) 10px,
        transparent 10px
      ),
      linear-gradient(to bottom, var(--primary) 10px, transparent 10px),
      linear-gradient(to left, var(--primary) 10px, transparent 10px),
      linear-gradient(to top, var(--primary) 10px, transparent 10px);
    background-size: 12px 1px, 1px 12px, 12px 1px, 1px 12px;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  }

  to {
    background-image: linear-gradient(
        to right,
        transparent 10px,
        transparent 10px
      ),
      linear-gradient(to bottom, transparent 10px, transparent 10px),
      linear-gradient(to left, transparent 10px, transparent 10px),
      linear-gradient(to top, transparent 10px, transparent 10px);
    background-size: 12px 1px, 1px 12px, 12px 1px, 1px 12px;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  }
}

@keyframes blink1 {
  0% {
    background: linear-gradient(
      180deg,
      var(--primary) 0,
      var(--primary) 50%,
      transparent 0,
      transparent
    );
    background-size: 1px 12px;
    background-repeat: repeat-y;
  }

  100% {
    background: transparent;
  }
}

$animationTime: 600ms;

.first {
  .firstDashedLine {
    position: absolute;
    top: $bubblePaddingTop + $bubbleMargin;
    height: $height - $bubblePaddingTop -$bubbleMargin + $tableContainerMargin;
    left: $baseBubbleWrapSize/2 + $barWrapPaddingLeft + $bubblePaddingLeft;
    width: 1px;
    background: linear-gradient(
      180deg,
      var(--primary) 0,
      var(--primary) 50%,
      transparent 0,
      transparent
    );
    z-index: 100;
    background-size: 1px 12px;
    background-repeat: repeat-y;
    animation: blink1 $animationTime ease-in-out infinite alternate;
  }

  .pointAt {
    visibility: visible;
    animation: point ease-in-out $animationTime alternate infinite;
  }

  table {
    th:nth-child(2) {
      animation: blink $animationTime ease-in-out infinite alternate;
    }
  }
}

.baseWrap {
  position: relative;
  width: $baseBubbleWrapSize;
  height: 100%;

  &:has(.active) {
    color: var(--primary);
  }
}

.baseWrap:last-of-type .line {
  display: none;
}

.line {
  position: absolute;
  left: 50%;
  right: 50%;
  width: 100%;
  top: $baseBubbleWrapSize + $baseWrapPadding * 2;
  border-top: 1px solid #d9d9d9;
}

.pointAt {
  position: absolute;
  visibility: hidden;
  left: $baseBubbleWrapSize / 2 + $bubblePaddingLeft;
  transform: translateX(-50%) translateY(-100%);
}

.sawtooth {
  height: 8px;
  width: 1px;
  position: absolute;
  left: 50%;
  top: $baseBubbleWrapSize + $baseWrapPadding * 2;
  background-color: #d9d9d9;
}
.circleWrap {
  width: 100%;
  padding: $baseWrapPadding 0;
  height: $baseBubbleWrapSize;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notFirst {
  .active {
    .dashedLine {
      background: linear-gradient(
        180deg,
        var(--primary) 0,
        var(--primary) 50%,
        transparent 0,
        transparent
      );
      background-size: 1px 12px;
      background-repeat: repeat-y;
    }
  }
}
.active {
  .circle {
    background-color: var(--primary);
  }
}
.circle {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  background-color: var(--light-primary);

  flex: 0 0 auto;

  &:hover {
    box-shadow: var(--box-shadow);
    border: 1px solid var(--primary);
    transform: scale(1.08);
  }
}

.dashedLine {
  height: $baseBubbleWrapSize + $baseWrapPadding * 2;
  width: 1px;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 0;
  z-index: 9;
  background: linear-gradient(
    180deg,
    #ddd 0,
    #ddd 50%,
    transparent 0,
    transparent
  );
  background-size: 1px 12px;
  background-repeat: repeat-y;
}

.axisInfoText,
.axisInfoHorText {
  text-align: center;
  margin-top: 13px;
  position: absolute;

  font: var(--helper-font);
  z-index: 2;
  text-wrap: nowrap;
}

.axisInfoText {
  left: -5px;
  transform: rotate(-45deg);
  bottom: 30px;
}
.axisInfoHorText {
  left: 0;
  right: 0;
  text-align: center;
}
.dateText {
  right: initial;
  transform: translateX(-50%);
  color: var(--secondary-text-color);
  background-color: #ffffff;
}

.bubble {
  will-change: transform;
  height: $height;
  display: flex;
  padding-left: 70px;
  flex: 1 1 auto;
  box-sizing: border-box;
  padding-top: $bubblePaddingTop;
  padding-left: $bubblePaddingLeft;
  margin-top: $bubbleMargin;

  & > div {
    flex: 0 0 auto;
  }
}

.circleTooltip {
  padding: 10px !important;
}

.hatStyle {
  text-align: center;
  padding: $hatStyleVerPadding 0;
  border-bottom: 1px solid;
  border-color: inherit;
  line-height: $hatStyleLineHeight;
}
