.tip {
  text-align: center;
  margin-top: 20px;
  color: var(--primary);
}

.chartWrap {
  height: max(30vh, 270px);
  margin-top: 40px;
}

.actionsWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div:first-child {
    margin-right: 10px;
  }
}

.emptyWrap {
  width: 70%;
  margin: 0 auto;
}

.container:has(.emptyWrap) {
  & > .tip {
    opacity: 0;
  }
}
