@import "../../../../styles/common.scss";

.legendWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.legendLeft {
  flex: 0 0 auto;
  margin-right: 20px;
}

.legend {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-wrap: wrap;
  margin-left: 10px;

  & > div {
    margin-right: 20px;
    cursor: pointer;
  }
}

.barAndInfo {
  width: 100%;
  padding-right: 40px;
  display: flex;

  & > div {
    align-items: stretch;
  }

  .imgWrap {
    width: 104px;
    height: 104px;
    margin-right: 20px;

    & img {
      max-width: 104px;
      max-height: 104px;
    }
  }

  .info {
    flex: 0 0 auto;
    margin-right: 20px;

    & > div {
      display: flex;
      height: 104px;
      align-items: center;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 28px;
      }
    }
  }

  .bar {
    flex: 1 1 auto;
    canvas {
      width: 100% !important;
    }
  }
}

.control {
  cursor: pointer;
  padding-right: 20px;
}

.fillCircle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 6px;
}

.asin {
  margin-right: 4px;
  margin-bottom: 8px;
}

.contentContainer {
  & > div:not(.asin) {
    color: var(--secondary-text-color);
    margin-top: 4px;
  }
}

.features {
  font-family: "Inter-Medium";
  max-width: 185px;
  height: 20px;
  line-height: 20px;

  @extend .lineEllipsis;
  -webkit-line-clamp: 1;
}

.lengendSpan {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  width: 18px;
  height: 12px;
  margin-right: 6px;
  border-radius: 2px;
}
