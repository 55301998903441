$height: 177px;
$marginBottom: 20px;
$infoMarginRight: 30px;

$borderRadius: 4px;
$baseBubbleWrapSize: 64px;
$baseWrapPadding: 8px;
$bubblePaddingTop: 26px;
$bubbleMargin: 14px;
$bubblePaddingLeft: 70px;
$barWrapPaddingLeft: 20px;

$tableContainerMargin: 10px;
$hatStyleVerPadding: 12px;
$hatStyleLineHeight: 17px;
$topHeight: 104px;

:export {
  height: $height;
  baseBubbleWrapSize: $baseBubbleWrapSize;
}
