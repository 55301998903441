.tableSearchWrap {
  & > div:nth-child(1),
  & > div:nth-child(2) {
    max-width: 400px;
    width: 20%;
  }

  & > div:nth-child(3) {
    max-width: 300px;
    width: 15%;
  }

  & > div:nth-child(4) {
    max-width: 250px;
    width: 15%;

    :global(.MuiInputBase-root) {
      width: 100%;
    }
  }
}
