@import "../../var";

.summary {
  border-bottom: 1px solid var(--secondary);

  a {
    color: var(--link-color);
  }
}

.listItemWrap {
  padding: var(--wrap-padding);

  border-bottom: 1px solid var(--secondary);
}

.listItem {
  position: relative;
  display: flex;
  align-items: stretch;

  & > div:first-child {
    flex: 0 0 auto;
  }
}

.title {
  padding: 0px 12px;
  text-align: left;
  margin-bottom: 12px;
}

.tip {
  color: var(--secondary-text-color);
  margin-top: 4px;
}

// .listWrap {

// position: relative;
// .area {
//   position: absolute;
//   top: 0px;
//   bottom: 0;

//   // background-color: rgba(0, 0, 0, 0.1);
//   // border-left: 1px dashed var(--primary);
//   // border-right: 1px dashed var(--primary);
//   right: 122px;
//   z-index: 10;

//   &::after {
//     width: 1px;
//     height: 100%;
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     border-left: 1px dashed var(--primary);
//   }
// }
// }
.listWrap {
  position: relative;
}
.rootListWrap {
  display: flex;
  // gap: 10px;

  .contentWrap {
    flex: 1 1 auto;
  }

  .area {
    width: 152px;
    flex: 0 0 auto;
    background-color: gainsboro;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    box-sizing: border-box;
    .ratio {
      flex: 1;
      background-color: #ffffff;
    }
    .ratio:has(.firstNum) {
      display: flex;
      flex-direction: column;
    }
    .num {
      &:not(.firstNum) {
        height: 100%;
      }
      box-sizing: border-box;
      color: var(--primary);
      display: flex;
      justify-content: center;
      align-items: center;

      border-bottom: 1px solid gainsboro;
    }

    .firstNum {
      flex-direction: column;
      flex: 1 1 auto;
    }

    :global(.MuiInputBase-root) {
      width: 100%;
    }
  }
  .areaTitleFixed {
    visibility: hidden;
    width: 130px;
    position: fixed;
    top: 0;
    background-color: gainsboro;
    z-index: 4;
    &.visible {
      visibility: visible;
    }
  }
  .areaTitle {
    position: sticky;
    top: 0;
    background-color: gainsboro;
  }
  .areaTitleFixed > div,
  .areaTitle > div {
    width: 100%;
    color: var(--color);
  }
}
