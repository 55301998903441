@import "../../../../styles/table";
@import "../../var";
.tableWrap {
  height: calc(98vh - 350px);
  width: 100%;
  overflow-y: auto;

  table {
    width: 100% !important;
  }
}

.tableContainer {
  margin-top: $tableContainerMargin;
  padding-bottom: 56px;

  border: 1px solid var(--secondary);
  border-radius: var(--border-radius);
}

.btns {
  padding: 12px 10px;
  box-shadow: var(--box-shadow);
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  z-index: 1009;

  bottom: 0px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  & > div:first-child {
    min-width: 100px !important;
    margin-right: 10px;
  }

  & > div,
  .right > div,
  .right > button {
    height: 100%;
  }

  :global(.icon-next)::before,
  :global(.icon-previous)::before {
    font-size: 12px;
  }

  :global(.disabled) {
    :global(.icon-next)::before,
    :global(.icon-previous)::before {
      color: #dddddd;
    }
  }
}

.keywordPaper {
  margin-top: -36px !important;
}
