$paddingHorizontal: 8px;
$wrapPaddingHorizontal: 10px;
$horSUm: $paddingHorizontal + $wrapPaddingHorizontal;
$maskTop: 160px;
$maskWidth: 36px;

.wrap {
  display: flex;
  min-height: 100vh;
}

.contentWrap {
  flex: 1 1 auto;
  min-width: 660px;
  max-width: 100%;
}

.bar,
.shrinkedBar {
  position: fixed;
  box-sizing: border-box;
  height: 100%;
  padding-top: 18px;
  z-index: 999;
  left: 0;
  top: 0;
  width: var(--bar-width);
  overflow: hidden;

  display: flex;
  flex-direction: column;

  background-color: var(--bar-background);
  border-right: 1px solid var(--secondary);
}

.logo {
  font-family: "Inter-Bold";
  margin-bottom: 30px;
  margin-left: $horSUm;
  position: relative;
  height: 36px;
  line-height: 36px;
}

.mask {
  position: absolute;
  top: 0;
  bottom: 0;
  width: $maskWidth;
  background-color: var(--bar-background);
  border-right: 1px solid var(--secondary);
  z-index: 99;
}

.sideWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  &:hover {
    z-index: 1003;
  }
}

.sidebarOnceShink {
  transform: translateX(-100%);
}

@keyframes onceFadeIn {
  to {
    transform: translateX(0);
  }
}

@keyframes onceFadeOut {
  to {
    transform: translateX(-100%);
  }
}

.sidebarOnceFadeOut {
  animation: onceFadeOut ease-in-out 500ms;
  animation-fill-mode: forwards;
}
.sidebarOnceFixed {
  animation: onceFadeIn ease-out;
  animation-fill-mode: forwards;
}

.content {
  flex: 1 1 auto;
  overflow: auto;
}

.sub {
  margin-bottom: 12px;
  padding: 0 $wrapPaddingHorizontal;

  & .title {
    padding: 6px $paddingHorizontal;
    color: #807b81;
  }
}

.navWrap {
  border-radius: var(--border-radius);
  padding: 9px $paddingHorizontal;

  & > a {
    display: flex;
    align-items: center;
  }
}

.navWrap:hover {
  background-color: var(--bar-hover-background);
}

.navWrap:has(:global(a.active)) {
  color: var(--primary);
  background-color: var(--mini-primary);
}

.navTop,
.navTopPlaceHolder {
  width: 36px;
  height: 36px;
  top: $horSUm;
  left: 0;
  border-right: 1px solid var(--secondary);
}
.navTop {
  position: fixed;
  z-index: 1000;
}

.navTopPlaceHolder {
  position: absolute;
  z-index: 999;
}

.btn {
  position: absolute;
  top: 0;
  right: $paddingHorizontal;
  width: 36px;
  height: 36px;
  border-radius: var(--border-radius);
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  line-height: 36px;
  &:hover {
    background-color: var(--bar-hover-background);
  }
}

.navTop .btn,
.navTopPlaceHolder .btn {
  border-radius: 0;
  right: 0;
  background-color: var(--bar-hover-background);
}

.bottom {
  width: 100%;
  box-sizing: border-box;
  left: 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    height: 1px;
    background-color: var(--secondary);
    left: $wrapPaddingHorizontal;
    width: calc(100% - $wrapPaddingHorizontal * 2);
  }

  .utilsWrap {
    padding: 22px $wrapPaddingHorizontal;
  }

  .user {
    height: 52px;
    line-height: 52px;
    padding: 0 0 0 $paddingHorizontal;
    cursor: pointer;
    margin: $wrapPaddingHorizontal;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -$wrapPaddingHorizontal;
      height: 1px;
      background-color: var(--secondary);
      left: -$wrapPaddingHorizontal;
      right: -$wrapPaddingHorizontal;
    }
  }

  .name {
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: var(--primary);
    color: #fff;
    text-align: center;
  }
}

.icon {
  margin-right: 10px;
  vertical-align: middle;
}

.horizontalBar {
  margin: $horSUm 0 var(--breadcrumbs-distance);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionWrap {
  & > div {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
  }

  .actionItem {
    padding: 8px;

    :global([class^="icon-"]) {
      &::before {
        color: inherit !important;
      }
    }
  }

  span {
    vertical-align: middle;
  }

  :global([class^="icon-"]),
  svg {
    font-size: 18px;
    margin-right: 6px;
  }

  svg {
    vertical-align: middle;
  }
}

.routeWrap {
  .navWrap:not(:last-child) {
    margin-bottom: 3px;
  }
}
