@import "../../var";

// $baseLine: $hatStyleVerPadding * 2 + $hatStyleLineHeight + 1;
$baseLine: $topHeight + 1;
.actionViewWrap {
  position: relative;
  flex: 1 1 auto;
}

.canvasWrap {
  position: absolute;
  height: $topHeight + 8;
  width: calc(100% + 10px);
  left: -4px;
  cursor: pointer;
}

.line {
  height: 1px;
  position: absolute;
  left: -40px;
  right: 0;
  top: $baseLine;
  background-color: var(--primary);
}

.whiteBack {
  position: absolute;
  z-index: 10;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid var(--primary);
  top: calc($baseLine + 8px);
  transform: translateX(-50%) translateY(-100%);
}

.encryptAdIdsWrap {
  background-color: var(--per04-primary);
  color: var(--primary);
  padding: 2px;
  cursor: pointer;
  text-align: center;
  min-width: 28px;
  box-sizing: border-box;
  text-align: center;

  &.active,
  &.start {
    background-color: var(--primary);
    color: #ffffff;
  }

  &.active {
    height: 46px;
  }
}

.tooltipWrap {
  z-index: 1199 !important;
}

.shortLine {
  top: $baseLine;
  position: absolute;
  background-color: var(--primary) !important;
}

.dateText {
  bottom: 0px !important;
}

.border {
  padding: 2px;
  background-color: #ffffff;
}

.placeBackground {
  position: absolute;
  color: transparent;
  left: -40px;
  pointer-events: none;
  right: 0;
  top: 0;
  height: $baseLine;
  box-sizing: border-box;
  background-color: var(--mini-primary);
  border: none;
  & > span {
    position: relative;
    z-index: -1;
  }
}

.startTooltip {
  border: 1px solid var(--primary);
  padding: 0 !important;
  margin-top: -($topHeight + 50) !important;
  & > :global(.MuiTooltip-arrow) {
    overflow: visible;

    &::after {
      position: absolute;
      content: "";
      left: 50%;
      height: 100px;
      width: 1px;
      border-left: 1px dashed gray;
    }
  }

  & > div {
    padding: 10px;
    border-radius: var(--border-radius);
    position: relative;
    background-color: #ffffff;
    z-index: 2;
  }
  :global(.MuiTooltip-arrow)::before {
    box-sizing: border-box;
    border: 1px solid var(--primary);
  }
}
.verticalLine {
  position: absolute;
  display: inline-block;
  width: 1px;
  height: 74px;

  background: linear-gradient(
    180deg,
    #f4eff6 0,
    #f4eff6 50%,
    transparent 0,
    transparent
  );
  background-size: 1px 12px;
  background-repeat: repeat-y;
}

.tips {
  position: absolute;
  top: 0;
  transform: translateY(-110%);
}

.mark {
  position: absolute;
  left: 0;
  top: 3px;
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  z-index: 100;
  pointer-events: none;

  &.line {
    width: 0px !important;
    right: initial;
    border-left: 1px dashed #b2a3a3;
  }
}
