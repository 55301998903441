.modalWrap {
  box-sizing: border-box;
  padding: 20px 24px;
  position: absolute;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max(min(800px, 40%), 400px);
  outline: none;
  border-radius: var(--border-radius);
  min-height: 150px;

  :global(.btnsWrap) {
    float: right;
    margin-top: 24px;
  }

  :global(.cancelBtn) {
    margin-right: 10px;
    display: inline-block;
    // float: right;
    color: var(--color);
    border-color: var(--color) !important ;
  }
  :global(.okBtn) {
    display: inline-block;
    // float: right;
    background-color: #222222;
    color: #ffffff;
  }
}

.title {
  margin-bottom: 20px;
}

.content {
  & > p:not(:last-child) {
    margin-bottom: 12px;
  }

  ul {
    list-style: outside;
    padding-left: 20px;
  }
}

.contentWrap {
  max-height: 50vh;
  overflow-y: auto;
}
