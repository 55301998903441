.imgWrap,
.toolWrap {
  background-image: url(./f.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 55% auto;
}

.imgWrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--hover-background);

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
}

tr:has(.imgWrap):hover,
li:has(.imgWrap):hover {
  .imgWrap {
    background-color: #ffffff;
  }
}

.hoverZoom {
  cursor: pointer;
}

.toolWrap {
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }
}

.loaded {
  background: #ffffff;
}

.arrangeWrap {
  display: grid;
  grid-gap: 2px;
  grid-template-rows: repeat(1fr, 2);
}
