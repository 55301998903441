@import "../../../../styles/common.scss";

.asinInfoWrap {
  text-align: left;
  color: var(--secondary-text-color);
}

.infoDetail {
  margin-top: 9px;
  @extend .lineEllipsis;
}

.attributeTooltip {
  margin-top: 10px;
}

.attribute {
  margin-top: 5px;
  font-family: "Inter-Medium";
  font-weight: var(--medium-weight);
  @extend .lineEllipsis;
  -webkit-line-clamp: 1;
}

.rating {
  margin-top: 6px;
  opacity: 0;
}

.ratingShow {
  opacity: 1;
}

.infoWrap {
  border-bottom: 1px solid var(--secondary);
}

.btnsWrap {
  display: flex;
  align-items: center;
  & > button:not(:last-child) {
    margin-right: 10px;
  }
}

.left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.leftTip {
  padding-right: 20px;
  position: relative;
  margin-right: 20px;
}

.infoContainer {
  margin-right: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  line-height: 24px;

  :global(.icon-tip) {
    margin-left: 6px;
    margin-right: 20px;
  }

  .types {
    display: flex;
    flex-wrap: wrap;
    & > span {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

.btnsWrap,
.summaryWrap {
  flex: 0 0 auto;
}

.summary,
.leftTip {
  font-family: "Inter-Medium";
  font-weight: var(--medium-weight);
}

.keywords:not(:global(.disabled)) {
  cursor: pointer;
}

.sourceWrap {
  & > div:not(:first-child) {
    margin-top: 8px;
  }
}

.sourceCategory {
  font-family: "Inter-Bold";
  font-weight: var(--bold-weight);
  line-height: 19px;
  color: #222;
}

.sourceWrapHover {
  min-width: 100px;

  ul {
    margin-top: 4px;
  }
}
