.linkCursor {
  padding-right: 10px;
  color: var(--link-color);
  cursor: pointer;
}

.title {
  margin-bottom: 12px;
}

.tableWrap {
  position: relative;
  overflow-y: auto;
  border: 1px solid var(--secondary);
  border-radius: var(--border-radius);

  tbody > tr:last-child > td {
    border-bottom: none;
  }
}

.loadingWrap {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(50% + 28px);
}
