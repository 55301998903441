@import "../../../../styles/common.scss";

.globalActionWrap {
  position: sticky;
  bottom: 0px;
  box-sizing: border-box;
  padding: 12px 0;
  z-index: 99;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--secondary);
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
}

.info {
  height: 60px;
  margin: 0 20px;
}

.globalBtn {
  border: 1px solid var(--color);
  background-color: #ffffff;
  margin-right: 10px;
  line-height: 20px;

  &:hover {
    background-color: var(--hover-background);
  }
}

.asinTitle {
  @extend .lineEllipsis;
  margin: var(--table-info-distance) 0 26px;
  color: var(--secondary-text-color);
  -webkit-line-clamp: 3;
}

.rating {
  color: var(--secondary-text-color);
}

.lineTwo {
  -webkit-line-clamp: 2;
}

.asinInfoWrap {
  height: 105px;
}
