.flag,
.miniFlag {
  height: 20px;
  width: 30px;
  background: white;
  margin-right: 20px;
  text-align: center;
  display: inline-block;
  box-shadow: var(--box-shadow);

  img {
    height: 100%;
  }
}

.miniFlag {
  display: inline-block;
}
