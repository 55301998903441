$ping-left: pingLeft;
$ping-right: pingRight;

.#{$ping-left} {
  .leftLast {
    &::after {
      width: 30px;
      right: -30px;
      box-shadow: inset 10px 0 8px -8px rgba(5, 5, 5, 0.06);
    }
  }
}

.#{$ping-right} {
  .rightLast {
    &::after {
      width: 30px;
      left: -30px;
      box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
    }
  }
}

.leftLast,
.rightLast {
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    pointer-events: none;
    content: " ";
  }
}

:export {
  leftLast: leftLast;
  rightLast: rightLast;
  pingLeft: $ping-left;
  pingRight: $ping-right;
}
