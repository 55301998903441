.wrap {
  display: flex;
  width: 100%;
  & > div {
    padding: var(--paper-menulist-ver) 0;
    width: 100%;
  }

  & > div:first-child:not(:last-child) {
    border-right: 1px solid var(--secondary);
    // box-shadow: 1px 0px 8px 0px var(--box-shadow-color);
  }

  .right > :global(.MuiButtonBase-root) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.wrap:has(.right) {
  & > div:first-child {
    li {
      padding-right: 20px;
    }
  }
}
.menu > ul {
  padding: 0 !important;
}

.select {
  height: 100px;
}

.timeWrap {
  position: relative;
  height: 100%;
}
