.tooltipEl {
  box-sizing: border-box;
  padding: 10px;
  position: absolute;
  z-index: 1201;

  border-radius: var(--border-radius);
  border: 1px solid var(--secondary);
  background-color: #ffffff;
  pointer-events: none;

  box-shadow: var(--box-shadow);

  color: #000000;
}

.tooltipEl,
.tooltipElNoStyle {
  :global(.wrapTable) > div:not(.title, .tips) {
    margin-top: 4px;
  }

  .title {
    color: #6b6a64;
    margin-bottom: 10px;
  }

  &:has(:global([class^="bar"])) {
    :global(.line-circle) {
      margin-left: 2px;
      margin-right: 8px;
    }
  }
}

.tips {
  color: var(--secondary-text-color);
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid var(--secondary);
}

.dataSpan {
  float: right;
  font-family: "Inter-Bold";
  font-weight: var(--bold-weight);
  margin-left: 10px;
}

:global([class^="bar"]) {
  display: inline-block;
  width: 12px;
  height: 8px;
  border-radius: 2px;
  margin-right: 6px;
}

:global(.line-circle) {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
}

:global(.line-triangle) {
  display: inline-block;
  background-color: white !important;
  border-right: 4px solid transparent !important;
  border-left: 4px solid transparent !important;
  position: relative;
  border-bottom: 8px dashed;
  margin-right: 3px;
  width: 0;
  height: 0;

  &::before {
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    content: " ";
    left: -3px;
    bottom: -7px;
    border-right: 3px solid transparent !important;
    border-left: 3px solid transparent !important;
    border-bottom: 5px solid white !important;
  }
}

$arrowSize: 8px;

:global([class^="tooltip-container"]) {
  width: max-content;
  min-width: 208px;
  max-width: 100%;
  text-align: left;

  :global(.arrow) {
    position: absolute;
    border: $arrowSize;
    border-color: transparent;
    border-style: solid;
    left: 50%;

    &::after {
      position: absolute;
      border-style: solid;
      content: " ";
      left: 0;
      border-width: $arrowSize;
      border-color: transparent;

      margin-left: -$arrowSize;
    }
  }

  :global(.arrow[placement^="bottom"]) {
    border-top-width: 0;
    border-bottom-color: #ebeef5;
    top: 0;
    transform: translate(-50%, -100%);

    &::after {
      top: 2px;
      border-color: transparent;
      border-top-width: 0;
      border-bottom-color: #fff;
    }
  }

  :global(.arrow[placement^="top"]) {
    border-bottom-width: 0;
    border-top-color: #ebeef5;
    bottom: 0;
    transform: translate(-50%, 100%);
    &::after {
      bottom: 2px;
      border-color: transparent;
      border-bottom-width: 0;
      border-top-color: #fff;
    }
  }
}

.medium {
  font-family: "Inter-Medium";
  font-weight: var(--medium-weight);
}
