@import "../../styles/common.scss";

.selectWrap {
  display: inline-flex;

  height: 20px;
  line-height: 20px;
  align-items: center;

  & > div:not(:first-child) {
    margin: 0 2px 0 var(--icon-distance);
  }
}

.title {
  margin-left: var(--icon-distance);
}

.option {
  display: inline-block;
}

.label {
  margin-right: 6px;
}

.activeWrap {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  &.active {
    :global(.icon-calendar::before) {
      color: var(--color);
    }
  }
}

.selectTextWrap {
  max-width: 100%;
}
.singleMenu {
  max-height: var(--max-paper-select-height) !important;
  :global(.MuiMenuItem-root.Mui-selected) {
    background-color: var(--miniBackSecondary) !important;
    border-color: transparent !important;
  }
}
.menuSelect {
  :global(.MuiMenuItem-root.Mui-selected) {
    border-width: 0 !important;
    background-color: transparent !important;
  }
  :global(.MuiMenuItem-root.MuiMenuItem-root) {
    border-width: 0 !important;
  }

  :global(.MuiMenuItem-root) {
    padding-left: var(--selectMenuHor) !important;
    padding-right: var(--selectMenuHor) !important;
    background-color: transparent !important;

    &:has(.apply) {
      padding: 0 !important;
    }

    &:not(:has(.apply)):hover {
      background-color: var(--hover-background) !important;
    }
  }

  .apply {
    width: 100%;
    justify-content: center;
    background-color: #222;
    color: #ffffff;
    margin: 10px var(--selectMenuHor) 12px;
  }
}

.labelShrinkWrap {
  display: flex;
  align-items: center;
  margin-right: 10px;
  & > span {
    flex: 0 1 auto;
    @extend .lineEllipsis;
    &:not(:has(:global(.extraLine))) {
      -webkit-line-clamp: 1;
    }
  }
}
