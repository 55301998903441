@import "./var.scss";
@import "./root.scss";

body {
  font-family: var(--font-family);
  color: var(--color);
  background-color: var(--background-color);
  position: relative;
}

strong {
  font-family: "Inter-Bold";
  font-weight: var(--bold-weight);
}

.disable-hover {
  pointer-events: none;
}

.icon-search-active::before {
  color: var(--color) !important;
}

.ScrollbarsCustom-Track {
  height: 8px !important;
  background-color: transparent !important;
}

.ScrollbarsCustom-Thumb {
  opacity: 1 !important;
  background-color: #d0cbd3 !important;
}

.iconCalendarWrap:has(.icon-calendar) {
  .MuiSelect-select {
    padding-left: calc(var(--selectMenuHor) + 14px) !important;
  }
  & > .icon-calendar {
    position: absolute;
    left: calc(var(--selectMenuHor) / 2);
    top: 50%;
    transform: translateY(-50%);
  }
}
