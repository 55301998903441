.button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.calendarWrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > div:not(:last-child) {
    margin-right: 20px;
  }
}

.month:hover:not(.selected):not(.disabeld) {
  background-color: var(--hover-background);
}

.month {
  width: 40px;
  height: 32px;
  box-sizing: content-box;
  padding: 0 10px;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color);
}

.rangeWrap .month {
  width: 50px;
}

.active {
  color: var(--primary);
}

.yearContent {
  font-size: var(--normal-16px);
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;

  & > span {
    display: inline-block;
    width: 35%;
    text-align: center;
  }
}

.yearContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0px 0px 16px;
  width: 100%;

  button {
    display: flex;
    align-items: center;
    width: 40px;
    cursor: pointer;
  }

  .nextButton {
    justify-content: end;
  }
}

.monthsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem 0;
}

.pickerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 8px 20px;
  border-radius: var(--border-radius);
  color: var(--color);
  width: var(--month-input-width);
}

.selected,
.rangeStart,
.rangeEnd,
.rangeActive {
  background-color: var(--primary);
  color: #ffffff;

  &:hover {
    background-color: var(--primary) !important;
    color: #ffffff !important;
  }
}

.rangeStart {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.rangeEnd {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.rangeMiddle {
  background-color: var(--mini-primary);
  border-radius: 0;

  color: var(--color) !important;

  &:hover {
    background-color: var(--mini-primary) !important;
  }
}

.pickerContainer::selection {
  background-color: transparent;
}

.disabeld {
  color: var(--tip-text-color);
  cursor: not-allowed;
}
