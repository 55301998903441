.title {
  margin: 16px 0 8px;
}

.wrap {
  ol {
    list-style: auto;
    padding-left: 15px;

    & > li:not(:last-child) {
      margin-bottom: 2px;
    }
  }
}
