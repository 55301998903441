.title {
  border-bottom: 1px solid var(--secondary);
}

.text {
  font-family: "Inter-Medium";
  font-weight: var(--medium-weight);
  margin-right: 6px;
}

.container {
  padding: 20px 20px 0;
}
