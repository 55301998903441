.wrap {
  padding: var(--example-distance) 0 var(--wrap-distance);
  width: var(--search-wrap-width);
  margin: 0 auto;
  display: flex;
  // float: left;
  // position: absolute;
}

:global(.pageWrap) {
  &:has(:global(.wrap):not(:global(.not-content))) {
    .wrap {
      display: none;
    }
  }
}

.example {
  flex: 1 1 auto;
}

.title {
  margin-right: 12px;
}

.option {
  cursor: pointer;
  color: var(--link-color);
  &:not(:first-child) {
    margin-right: 16px;
  }
  &:hover {
    text-decoration: underline;
  }
}
