@import "../../_var.scss";
.center {
  display: flex;
  align-items: center;

  width: calc(100% - 24px);
  .title {
    margin-right: 10px;
  }
}

.barWrap {
  padding: 0 20px 0 $barWrapPaddingLeft;
  // border: 1px solid var(--secondary);
}

// .titleWrap {
//   display: inline-flex;
//   align-items: center;
// }

.titleContent {
  margin: 0 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.imgWrap {
  display: inline-block;
  width: 36px;
  height: 36px;
  margin-right: 8px;
}

.adDrawer {
  padding-bottom: 0px;
  padding-top: 0px;
  min-width: 825px;
}

.allLine {
  position: absolute;
  left: 0;
  right: 0;
  top: calc($baseBubbleWrapSize + $baseWrapPadding * 2 + 40px);
  height: 1px;
  background: var(--secondary);
}

.loadingWrap {
  [class^="loading_animateLoading"] {
    top: 50px !important;
  }
}
