.searchWrap {
  display: flex;
  box-sizing: border-box;
  padding: 12px 20px 0;
  flex-wrap: nowrap;
  align-items: end;
  column-gap: 10px;

  & > div {
    margin-bottom: 12px;
    min-height: 36px;
    flex-wrap: wrap;
  }

  & > div:not(:last-child) {
    flex: 1 1 auto;
    & > span {
      display: inline-block;
      margin-bottom: 8px;
    }
  }
  & > div:last-child {
    flex: 0 0 auto;
  }

  & input {
    height: 36px;
    width: 100%;
    box-sizing: border-box;
    padding-right: 18px;
  }
}

.label {
  margin-bottom: 8px;
}
