.topAction {
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  right: 10px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: var(--box-shadow);

  cursor: pointer;
}

.palceHolder {
  position: absolute;
  height: 2px;
  width: 100%;
  top: -200px;
  pointer-events: none;
  opacity: 0;
}
