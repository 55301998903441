.boxLoading {
  position: relative;
}
.topBoxLoading {
  min-height: 300px;

  .animateLoading {
    background-color: transparent;
  }
}

.animateLoading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;

  & > div:not(.fixed) {
    position: absolute;
    top: 50%;
    transform: scale(0.4) translateY(-125%);
  }
}

.fixed {
  position: sticky !important;
  margin-top: 200px;
  top: 200px;
  height: 80px;

  & > div {
    transform: scale(0.4) !important;
  }
}

@keyframes ldio-ccffhultovm-1 {
  0% {
    top: 26.39999999999999px;
    height: 147.20000000000002px;
  }
  50% {
    top: 54px;
    height: 92px;
  }
  100% {
    top: 54px;
    height: 92px;
  }
}
@keyframes ldio-ccffhultovm-2 {
  0% {
    top: 33.3px;
    height: 133.4px;
  }
  50% {
    top: 54px;
    height: 92px;
  }
  100% {
    top: 54px;
    height: 92px;
  }
}
@keyframes ldio-ccffhultovm-3 {
  0% {
    top: 40.199999999999996px;
    height: 119.60000000000001px;
  }
  50% {
    top: 54px;
    height: 92px;
  }
  100% {
    top: 54px;
    height: 92px;
  }
}
.ldio-ccffhultovm div {
  position: absolute;
  width: 22px;
}
.ldio-ccffhultovm div:nth-child(1) {
  left: 39px;
  background: var(--primary);
  animation: ldio-ccffhultovm-1 0.9174311926605504s cubic-bezier(0, 0.5, 0.5, 1)
    infinite;
  animation-delay: -0.1834862385321101s;
}
.ldio-ccffhultovm div:nth-child(2) {
  left: 89px;
  background: #222222;
  animation: ldio-ccffhultovm-2 0.9174311926605504s cubic-bezier(0, 0.5, 0.5, 1)
    infinite;
  animation-delay: -0.09174311926605505s;
}
.ldio-ccffhultovm div:nth-child(3) {
  left: 139px;
  background: var(--primary);
  animation: ldio-ccffhultovm-3 0.9174311926605504s cubic-bezier(0, 0.5, 0.5, 1)
    infinite;
  animation-delay: undefineds;
}

.loadingio-spinner-pulse-q8hv0b87789 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-ccffhultovm {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ccffhultovm div {
  box-sizing: content-box;
}
