@import "./_var.scss";
@import "./muiGlobal.scss";

:root {
  --primary: #{$primary};
  --mini-primary: #{$miniPrimary};
  --light-primary: #{$lightPrimary};
  --per04-primary: #{$per04Primary};
  --secondary: #{$secondary};
  --backSecondary: #{$backSecondary};
  --miniBackSecondary: #{$miniBackSecondary};

  --font-family: #{$font-family};

  --color: #{$color};
  --error: #{$error};
  --secondary-text-color: #{$secondary-text-color};
  --disabled-text-color: #{$disabled-text-color};
  --tip-text-color: #{$tip-text-color};

  --background-color: #{$background};
  --hover-background: #{$hover-background};

  --bar-background: #{$bar-background};
  --bar-width: #{$barWidth};
  --bar-hover-background: #{$bar-hover-background};

  --selectMenuDistance: #{$selectMenuDistance};
  --selectMenuHor: #{$selectMenuHor};

  --border-radius: #{$border-radius};

  //Medium  500
  //Semibold 600
  //Bold 700
  //Black 900
  --medium-weight: 500;
  --semibold-weight: 600;
  --bold-weight: 700;
  --black-weight: 900;

  // 导航标题
  --menu-title-font: 16px Inter-Medium;

  // 段落文本
  --normal-phase-font: 16px Inter;
  // 导航按钮
  --menu-btn-font: 18px Inter-Medium;
  // 顶部文本
  --top-text-font: 16px Inter;

  // 搜索按钮
  --search-btn-font: 16px Inter-Medium;
  // 搜索文本
  --search-text-font: 16px Inter;

  // 搜索前标题
  --faq-title-font: 24px Inter-Black;
  // 必读强调、列表标题
  --must-bold-font: 16px Inter-Bold;
  // 必读文本
  --must-text-font: 16px Inter;
  // 对话框标题
  --dialog-title-font: 20px Inter-Bold;

  // 表头标题、强调型文本
  --stress-text-font: 14px Inter-Medium;
  // 正文文本，按钮文本，对话框内容，表头标题
  --text-font: 14px Inter;
  --list-bold-text-font: 14px Inter-Bold;
  // 无数据标题
  --empty-title-font: 20px Inter-Semibold;
  // 无数据文本
  --empty-text-font: 14px Inter;
  // 列表总结文本
  --list-summary-font: 16px Inter-Medium;
  // 强调型正文文本
  --stress-list-font: 14px Inter-Semibold;

  // 辅助型文本
  --helper-font: 12px Inter;

  --normal-16px: 16px Inter;

  --underDashLine: 1px dashed #{$underBorderColor};

  --link-color: #{$link-color};

  --icon-distance: #{$icon-distance};

  --search-wrap-width: 74.7%;
  --box-shadow-color: rgba(182, 181, 177, 0.4);
  --box-shadow: 0px 2px 12px 0px rgba(182, 181, 177, 0.4);

  --wrap-padding: 20px;
  --max-paper-select-height: 400px;

  --table-info-distance: 8px;
  --selectMenuVer: #{$selectMenuVer};
  --paper-menulist-ver: #{$paperMenulistVer};

  --breadcrumbs-distance: 48px;
  --example-distance: 20px;
  --wrap-distance: 24px;
  --group-distance: 10px;
}

@media (max-width: 1440px) {
  :root {
    --menu-title-font: 14px Inter-Medium;
    --normal-phase-font: 14px Inter;
    --menu-btn-font: 16px Inter-Medium;
    --top-text-font: 14px Inter;
    // 搜索按钮
    --search-btn-font: 14px Inter-Medium;
    // 搜索文本
    --search-text-font: 14px Inter;
    // 对话框标题
    --dialog-title-font: 18px Inter-Bold;
    // 表头标题、强调型文本
    --stress-text-font: 13px Inter-Medium;
    // 正文文本，按钮文本，对话框内容，表头标题
    --text-font: 13px Inter;
    --list-bold-text-font: 13px Inter-Bold;

    // 列表总结文本
    --list-summary-font: 15px Inter-Medium;
    // 强调型正文文本
    --stress-list-font: 13px Inter-Semibold;

    --breadcrumbs-distance: 44px;
    --example-distance: 16px;
    --wrap-distance: 20px;
    --group-distance: 8px;
  }
}

@media (max-width: 1024px) {
  :root {
    --top-text-font: 13px Inter;
    // 搜索前标题
    --faq-title-font: 22px Inter-Black;
    // 必读强调
    --must-bold-font: 14px Inter-Bold;
    // 必读文本
    --must-text-font: 14px Inter;
    --normal-16px: 14px Inter;
    // 无数据标题
    --empty-title-font: 18px Inter-Semibold;
    // 无数据文本
    --empty-text-font: 13px Inter;
    // 列表总结文本
    --list-summary-font: 14px Inter-Medium;

    --breadcrumbs-distance: 40px;
    --wrap-distance: 16px;
    --helper-font: 11px Inter;
  }
}

@media (max-width: 800px) {
  :root {
    --top-text-font: 12px Inter;
    // 对话框标题
    --dialog-title-font: 16px Inter-Bold;
    // 表头标题、强调型文本
    --stress-text-font: 12px Inter-Medium;
    // 正文文本，按钮文本，对话框内容，表头标题
    --text-font: 12px Inter;
    --list-bold-text-font: 12px Inter-Bold;
    // 强调型正文文本
    --stress-list-font: 12px Inter-Semibold;
  }
}

strong {
  font-family: "Inter-Bold";
}

.menuTitleFont {
  font: var(--menu-title-font);
  font-weight: var(--medium-weight);
}

.normalPhaseFont {
  font: var(--normal-phase-font);
}

.menuBtnFont {
  font: var(--menu-btn-font);
  font-weight: 600;
  font-weight: var(--medium-weight);
}

.topTextFont {
  font: var(--top-text-font);
}

.searchBtnFont {
  font: var(--search-btn-font);
  font-weight: var(--medium-weight);
}

.searchTextFont {
  font: var(--search-text-font);

  .MuiMenuItem-root {
    font: inherit !important;
  }
}

.faqTitleFont {
  font: var(--faq-title-font);
  font-weight: var(--black-weight);
}

.mustBoldFont {
  font: var(--must-bold-font);
  font-weight: var(--bold-weight);
}

.mustTextFont {
  font: var(--must-text-font);
}

.dialogTitleFont {
  font: var(--dialog-title-font);
  font-weight: var(--bold-weight);
}

.stressTextFont {
  font: var(--stress-text-font);
  font-weight: var(--medium-weight);
}

.textFont {
  font: var(--text-font);
}

.listBoldTextFont {
  font: var(--list-bold-text-font);
  font-weight: var(--black-weight);
}

.emptyTitleFont {
  font: var(--empty-title-font);
  font-weight: var(--semibold-weight);
}

.emptyTextFont {
  font: var(--empty-text-font);
}

.listSummaryFont {
  font: var(--list-summary-font);
  font-weight: var(--medium-weight);
}

.stressListFont {
  font: var(--stress-list-font);
  font-weight: var(--semibold-weight);
}

.helperFont {
  font: var(--helper-font);
}

.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.hide {
  position: fixed;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.disabled {
  color: var(--disabled-text-color) !important;
  border-color: var(--secondary) !important;
  cursor: not-allowed !important;
  pointer-events: initial !important;

  .icon-word-freq {
    opacity: 0.5;
  }
}

.button {
  padding: 10px 12px !important;
  box-sizing: border-box;
  border-radius: var(--border-radius) !important;
  display: inline-flex;
  align-items: center;
  @extend .textFont;
  cursor: pointer;
  border: 1px solid var(--secondary);

  span {
    vertical-align: middle;
  }

  & > [class^="icon-"],
  & > svg {
    vertical-align: middle;
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
}

.button-hover {
  border: none;
  font-size: var(--normal-16px);
  &:hover {
    background-color: var(--bar-hover-background);
  }
}

.wrap {
  margin-top: var(--wrap-distance);
  border-radius: var(--border-radius);
  border: 1px solid var(--secondary);
  background-color: #ffffff;
}

.textOverFlow {
  white-space: nowrap;
  overflow: hidden; /* 隐藏超出的内容 */
  text-overflow: ellipsis;
}

.pageContentWrap {
  margin: 20px 0;
  & nav {
    display: flex;
    justify-content: center;
  }
}

.wrapMini:not(:has(tbody tr)) {
  min-height: 400px;
}

.tableLastNoBorder {
  table > tbody > tr:last-child {
    & > td {
      border-bottom: none;
    }
  }
}

.drawerTableWrap {
  &:has(.emptyDrawer) {
    & > div:first-child {
      height: 400px;
    }
  }

  .emptyDrawer {
    position: absolute;
    top: 290px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconButtonDisabled {
  cursor: not-allowed !important;

  [class^="icon-"] {
    &::before {
      color: var(--tip-text-color);
    }
  }
}

.underDashLine {
  display: inline-block;
  border-bottom: var(--underDashLine);
}

.wrapContentInfo {
  padding: 12px 20px;
}

.loadingWrap {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.btnsRightWrap {
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;

  & > div:not(:last-child),
  & > button:not(:last-child) {
    margin-right: 10px;
  }
}

.titleSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: var(--list-summary-font);
}
