.btnWrap {
  display: flex;
  justify-content: space-between;
  margin: 12px;
  align-items: center;
}

.chartWrap {
  border-top: 1px solid var(--secondary);
  padding: 40px 20px 20px;
  min-height: 300px;
}
