@import "../../../../styles/common.scss";
.info {
  box-sizing: border-box;
  min-height: 60px;

  & > div {
    display: flex;
  }
}
.title {
  @extend .lineEllipsis;
  font-family: "Inter-Medium";
}

.detailInfo {
  color: var(--secondary-text-color);
  & > div {
    margin-top: 4px;
  }
  & > div:first-child {
    margin-top: 11px;
  }
}
.hoverImgWrap {
  display: flex;
  & > div {
    flex: 0 0 auto;
  }
  & > div:first-child {
    width: 140px;
    height: 140px;
    margin-right: 12px;

    text-align: center;
    img {
      max-width: 140x;
      max-height: 140px;
      vertical-align: middle;
    }
  }
  & > div:last-child {
    width: 230px;
  }
}
.imgWrap {
  width: 39px;
  height: 100%;
  text-align: center;
  margin-right: 12px;

  position: relative;
  border-radius: var(--border-radius);
  cursor: pointer;
  img {
    vertical-align: middle;
    max-width: 39px;
    max-height: 39px;
  }
  &:hover {
    .placeHolder {
      opacity: 1;
    }
  }
}

.placeHolder {
  position: absolute;
  border-radius: var(--border-radius);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(34, 34, 34, 0.3);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  :global(.icon-amazon) {
    color: #ffffff !important;
  }
}

.tip {
  color: var(--secondary-text-color);
  text-align: center;
}
