.sliderWrap {
  --height: 30px;
  height: var(--height);
  position: relative;

  :global(.noUi-handle) {
    width: 20px;
    height: calc(var(--height) * 0.6);
    top: calc(var(--height) * 0.2);
    right: -10px;
    cursor: ew-resize;
  }

  :global(.noUi-handle:before),
  :global(.noUi-handle:after) {
    top: 20%;
    height: 60%;
  }
  :global(.noUi-handle:before) {
    left: 30%;
    right: initial;
  }

  :global(.noUi-handle:after) {
    left: initial;
    right: 30%;
  }
  :global(.noUi-connect) {
    background-color: rgba(102, 122, 255, 0.3);
  }

  &:hover,
  &:global(.noUi-state-drag) {
    .leftText,
    .rightText {
      opacity: 1;
    }
  }
}

.leftText,
.rightText {
  color: #635959;
  position: absolute;
  top: 50%;
  font: var(--helper-font);
  min-width: 70px;
  opacity: 0;
}
.leftText {
  transform: translateX(-120%) translateY(-50%);
}
.rightText {
  transform: translateX(20%) translateY(-50%);
}
