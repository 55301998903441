.lastRank > span,
.adLastRank > span {
  border-bottom: var(--underDashLine);
}
.tableWrap {
  margin-top: var(--group-distance) !important;
}
.highlight {
  color: var(--primary);
}
.tableBtns {
  & > .tip {
    font-family: "Inter-Medium";
    margin-right: 10px;
  }
  :global(.icon-word-freq) {
    font-size: 16px !important;
  }
}

.pointer {
  cursor: pointer;
}

.rankTipWrap {
  .time {
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .tip {
    padding-top: 8px;
    border-top: 1px solid var(--secondary);
    color: var(--secondary-text-color);
  }
}
