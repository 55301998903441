@use "sass:color";
@import "../../../../styles/var.scss";

.middle,
.middlely {
  display: inline-block;
  position: relative;
  flex: 0 1 auto;
  height: 100%;

  :global(.MuiBox-root) {
    height: 100%;
  }
  :global(.MuiInputBase-root) {
    width: 100%;
    height: 100%;
  }
}
.middle {
  width: 221px;
}
.middlely {
  width: 150px;
}
.signWrap {
  position: absolute;
  top: 0px;
  right: 6px;
  display: inline-block;
  transform: translateY(-50%);
  background-color: #ffffff;
  line-height: 15px;
}

.sign {
  color: #ffffff;
  padding: 2px 6px;
  background: color.adjust($primary, $alpha: -0.5);
  border-radius: 10px;
}
