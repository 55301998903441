.tableWrap {
  margin-top: 20px;
  overflow-x: hidden;

  height: calc(max(43vh, 370px) + 55px);
  & > div:first-child {
    border: 1px solid var(--secondary);
    border-radius: var(--border-radius);
    max-height: max(43vh, 370px);
    overflow-y: auto;
  }

  & > div:nth-child(2) {
    margin-bottom: 0;
  }
}

.export {
  text-align: right;
}
