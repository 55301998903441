.dataSearchWrap {
  padding: 20px 20px 8px 20px;
  flex-wrap: wrap;
  & > div:nth-child(1),
  & > div:nth-child(2) {
    max-width: 200px;
    min-width: 160px;
    width: 20%;
  }

  & > div:nth-child(3) {
    flex: 0 0 auto;
  }

  & > div:nth-child(4) {
    max-width: 300px;
    min-width: 180px;
    width: 20%;

    :global(.MuiInputBase-root) {
      width: 100%;
    }
  }
}
