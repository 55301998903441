$paddingHor: 24px;
.contentWrap {
  padding: 20px $paddingHor 40px;
}

.titleWrap {
  padding: 20px $paddingHor;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--secondary);

  :global(.icon-delete) {
    cursor: pointer;
    &::before {
      color: #68646b !important;
    }
  }
}
