.legendWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.legend {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-wrap: wrap;

  & > div {
    margin-right: 20px;
    cursor: pointer;
  }
}

.control {
  padding-right: 20px;
}

.fillCircle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 6px;
}

.hide {
  color: #a9a9a9;

  & .fillCircle {
    background-color: #a9a9a9 !important;
  }
}
