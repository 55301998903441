$headHeight: 36px;
$marginHor: 8px;
$marginVer: 2px;

.rdp {
  --rdp-accent-color: var(--primary);
  --rdp-background-color: var(--mini-primary);
  --rdp-selected-color: #ffffff;
  --rdp-cell-size: 36px;
  margin: $marginVer $marginHor;
  padding-bottom: 20px;
  font-family: "Inter";
}
.rdp-day_selected {
}

.rdp-day {
  width: 40px;
  max-width: 40px;
}
.rdp-table {
  border-collapse: separate;
}

.rdp-caption {
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: $headHeight;
  margin-bottom: 6px;

  .day-btn {
    cursor: pointer;
    border: none !important;
  }
}
.week-range-picker {
  position: relative;
  background-color: #ffffff;
  display: inline-block;
  overflow: hidden;
}

.rdp-head.rdp-caption {
  display: flex;
}
.rdp-table {
  position: relative;
}
.rdp-head_cell {
  font-weight: 400;
}
.rdp-row {
  border-radius: var(--border-radius);
  height: 40px;
}
.rdp-button_reset {
  font: var(--text-font);
}

.booked:not(.rdp-day_selected) {
  color: var(--primary);
}

.rdp-dropdown {
  max-height: 400px;
  overflow-y: auto;
}

.week-range-picker {
  .rdp-row:hover {
    button:not(.rdp-day_selected):not(.rdp-day[disabled]) {
      border-radius: 0;
      background-color: var(--rdp-background-color);
    }
    & > td:first-child > button:not(.rdp-day_selected) {
      border-radius: var(--border-radius) 0 0 var(--border-radius) !important;
    }

    & > td:last-child > button:not(.rdp-day_selected) {
      border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
    }
  }
}

.rdp-day_range_end,
.rdp-day_range_start,
.rdp-day_range_end.rdp-day_range_start {
  border-radius: var(--border-radius);
}
.rdp-day_outside {
  opacity: 1;
  color: var(--tip-text-color);
}

.rdp-day[disabled] {
  cursor: not-allowed;
  color: var(--tip-text-color);

  &:not(.rdp-day_selected) {
    opacity: 0.4;
  }
}
.rdp-dropdown_month > .rdp-caption_label {
  margin-right: 10px;
}
.rdp-dropdown_icon {
  margin-left: 6px;
}
.rdp-caption_label {
  padding: 0px 12px;
  line-height: $headHeight;
  font-weight: normal;
  font-size: var(--normal-16px);
  border-radius: var(--border-radius);
  border: none;

  &.active {
    color: var(--primary);
  }

  &:hover {
    background-color: var(--hover-background);
  }
}

.multiple-week-range-picker {
  .rdp-caption_start .icon-next {
    display: none;
  }
  .rdp-caption_end .icon-previous {
    display: none;
  }
  .rdp-caption_between .icon-next,
  .rdp-caption_between .icon-previous {
    display: none;
  }
  .rdp-day_range_middle {
    &:not(.rdp-day_disabled) {
      color: var(--color);
    }

    background-color: var(--mini-primary);
  }
  .rdp-day_outside {
    color: var(--tip-text-color) !important;
    background-color: #ffffff;
  }

  &:has(.rdp-day_range_end.rdp-day_range_start) {
    .rdp-row:has(.rdp-day_selected) {
      button {
        border-radius: 0;
      }
      & > td:first-child > button {
        border-radius: var(--border-radius) 0 0 var(--border-radius) !important;
      }

      & > td:last-child > button {
        border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
      }
      .rdp-cell:not(:has(.rdp-day_outside)):not(:has(.rdp-day_range_start))
        button {
        background-color: var(--mini-primary);
      }
    }
  }
}

#simple-popover {
  position: absolute;

  .MuiBackdrop-root {
    display: none;
  }
  .MuiPaper-root {
    overflow-y: auto;
    margin: 0;
    padding: 14px 20px;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0px !important;
    box-shadow: none !important;
    max-height: none !important;
    max-width: none !important;
    box-sizing: border-box;
  }

  .popWrap-years,
  .popWrap-months {
    display: grid;
    grid-gap: 28px 36px;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;

    & > div {
      display: inline-block;
      text-align: center;
      box-sizing: border-box;
      height: 32px;
      line-height: 32px !important;

      cursor: pointer;
      border-radius: var(--border-radius);
      font: var(--text-font);
      &:hover {
        background-color: var(--hover-background);
      }
      &.now:not(.active) {
        color: var(--primary);
      }
      &.active {
        background-color: var(--primary);
        color: #ffffff;
      }
    }
  }

  .popWrap-months {
    width: 200px;
    & > div {
      width: 40px;
    }
  }

  .popWrap-years {
    width: 215px;
    & > div {
      width: 55px;
    }
  }
}
