.wrap {
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  strong {
    font: var(--must-bold-font);
  }
}

:global(.pageWrap) {
  .wrap {
    width: var(--search-wrap-width);
  }
  &:has(:global(.wrap):not(:global(.not-content))) {
    .wrap {
      width: 100%;
    }
  }
}
.mustReadWrap {
  padding: 0 13%;
}
.mediaWrap {
  border: none;
}
.title {
  margin: 48px 0;
  text-align: center;
}
.btn {
  margin-right: 28px;
}
.btnsWrap {
  margin: 16px 0 40px;
  span {
    display: inline-block;
  }
}

.contet {
  a {
    color: var(--link-color);
  }
  p {
    margin-bottom: 24px;
  }
  border-bottom: 1px solid var(--secondary);
}

.mediaWrap {
  margin-bottom: 24px;
  img {
    width: 100%;
  }
}
