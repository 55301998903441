.emptyWrap {
  width: 100%;
  height: 100%;

  text-align: center;
  & img {
    max-width: 80%;
    max-height: 80%;
  }
  .tipText {
    margin-top: 12px;
    color: var(--secondary-text-color);
  }
}
