.wrap {
  & > p:last-child:has(a:first-child:last-child) {
    margin-top: 20px;
  }

  .tipHTMLWrap {
    & > p:not(:last-child) {
      margin-bottom: 12px;
    }

    :global(.firstA) {
      padding-top: 8px;
    }

    :global(.externalFirstA) {
      margin-top: 12px;
    }
    &:not(:has(:global(.firstA))) {
      & > p:has(a:first-child:last-child):last-child {
        padding-top: 8px;
      }
    }
  }

  a {
    color: var(--link-color);
    cursor: pointer;
  }

  ul {
    list-style: none;

    & > li {
      display: flex;
    }

    li::before {
      content: "•";
      display: inline-block;
      margin-right: 2px;
      color: inherit;
    }

    & > li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.content {
  padding: 10px 0;
}

.tipHTMLWrap:not(.other) {
  line-height: 20px;
  & > div,
  & > ul >,
  & > p {
    line-height: initial;
  }
}

:global(.MuiTooltip-tooltip) {
  line-height: 20px;
  & > div {
    line-height: initial;
  }
}
