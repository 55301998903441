.btn {
  border: 1px solid var(--secondary);
  border-left: 1px solid transparent;
  background-color: white;
  display: inline-block;
  cursor: pointer;
}

.btn:first-child {
  border-left-color: var(--secondary);
  border-radius: var(--border-radius) 0px 0px var(--border-radius) !important;
}

.btn:not(:first-child):not(:last-child) {
  border-radius: 0 !important;
}

.btn:last-child {
  border-radius: 0px var(--border-radius) var(--border-radius) 0px !important;
}
.preActive {
  border-right: 1px solid transparent;
}

.btn:first-child:last-child {
  border-radius: var(--border-radius) !important;
}

.btn.active {
  color: var(--primary);
  border-color: var(--primary);
}
