.imgWrap {
  margin-left: 6px;
  width: 24px;
  height: 24px;
}
.container {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
.contentContainer {
  :global(.MuiMenuItem-root) {
    padding: 6px 12px !important;
    font: var(--stress-list-font);
    background-color: #ffffff;
    border-left: none !important;
  }
}

.dateTitle {
  position: sticky;
  top: 0;
  z-index: 8;
  padding: 0 12px;
  background-color: #ffffff;

  & > div {
    padding: 12px 0;
    border-bottom: 1px solid var(--secondary);
    height: 100%;
  }
}

.adGroupSelect {
  width: 240px;
  height: 36px;
  border-color: var(--secondary) !important;

  &.change {
    border-color: var(--color) !important;
  }

  & > div:first-child {
    width: 100%;
    height: 100%;
    line-height: 36px;
  }

  :global(.MuiSelect-select) {
    padding-top: 0;
    padding-bottom: 0;
  }

  :global(.MuiOutlinedInput-notchedOutline) {
    border-color: inherit !important;
    border-width: 1px !important;
  }
}

.menuPaper {
  max-height: var(--max-paper-select-height) !important;
  overflow-y: auto !important;
}

.imgClass {
  width: 20px;
}

.valueWrap {
  display: flex;
  align-items: center;
  height: 100%;

  img {
    margin-left: 6px;
    max-width: 20px;
    max-height: 30px;
  }
}
