.infoWrap {
  color: var(--color);
  :global([class^="icon-"]) {
    font-size: 13px !important;
    vertical-align: top;
    display: inline-block;
    margin-top: 2px;
  }
}

.infoIcon {
  display: inline-block;
  margin-left: 6px;
  height: 14px;
  cursor: pointer;

  & img {
    height: 100%;
  }
}

.hoverImageWrap {
  position: relative;
}

.va {
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;

  img {
    width: 56px;
  }
}

$height: 120px;
.topImags {
  width: $height * 3 + 24px;
  .title {
    margin-bottom: 12px;
    font-family: "Inter-Bold";
    font-size: var(--normal-16px);
    font-weight: var(--bold-weight);
  }

  .keywordImgsWrap {
    padding-bottom: 12px;
    position: relative;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(3, 1fr);

    &.oneGrid {
      grid-template-columns: repeat(1, 1fr);
    }

    height: $height * 2 + 24px;
    width: 100%;

    & > div {
      img:not(:global(.emptyImg) img) {
        max-width: $height;
        max-height: $height;
      }
    }
    border-bottom: 1px solid var(--secondary);
  }
  .tips {
    margin: 12px 0;
  }

  .btns {
    margin-bottom: 10px;

    & > div {
      display: inline-block;
      color: var(--primary);
      border-color: var(--primary);
      &:first-child {
        margin-right: 12px;
      }

      :global(.icon-amazon) {
        color: inherit;
      }
    }
  }
}

.keyword {
  font-family: "Inter-SemiBold";
  font-weight: var(--semibold-weight);
}

.pointer {
  cursor: pointer;
}

.estSearchesNum > span {
  border-bottom: var(--underDashLine);
}
