.content {
  max-height: 160px;
  overflow-y: auto;
}
.header {
  margin-bottom: 10px;
}
.subHeader {
  position: sticky;
  top: 0px;
  background-color: #ffffff;

  &:not(.first) {
    margin-top: 10px;
  }
}

.highLight {
  color: var(--primary);
}

.groupItem {
  cursor: pointer;
  color: var(--link-color);

  & > div {
    padding: 4px 0 2px;
    display: block;

    & > span {
      border-color: var(--link-color);
    }
  }
}
