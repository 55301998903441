.inputRangeWrap {
  background-color: #ffffff;
  display: inline-flex;
}

.left {
  width: 170px;
  border-right: 1px solid var(--secondary);
  padding-top: 20px;

  & > div:not(.title) {
    box-sizing: border-box;
    padding: 12px 30px;
    cursor: pointer;
    border-left: 3px solid transparent;

    &:hover {
      background-color: var(--hover-background);
    }
  }

  .title {
    padding: 0px 30px;
    margin-bottom: 12px;
  }
}

.title {
  margin-bottom: 8px;
}

.quickOption {
  font-size: var(--normal-16px);
}

.active {
  box-sizing: border-box;
  background-color: var(--mini-primary);
  border-color: var(--primary) !important;

  &:hover {
    background-color: var(--mini-primary) !important;
  }
}

.right {
  padding: 20px;
}

.rangePicker {
  margin-top: 32px;
}

.inputWrap {
  display: flex;

  & > div {
    position: relative;
    flex: 1;
  }

  & input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 12px;
    border-radius: var(--border-radius);
    border: 1px solid var(--secondary);
    outline: none;

    &:focus {
      border-color: var(--backSecondary);
    }
  }

  input.error {
    border-color: var(--error);
  }
}

.tip {
  position: absolute;
  margin-top: 4px;
  color: var(--error);
  line-height: 17px;
  z-index: 100;

  & > span {
    vertical-align: middle;
  }

  :global(.icon-tip) {
    vertical-align: middle;
    margin-right: 2px;

    &::before {
      color: inherit;
    }
  }
}

.buttonWrap {
  text-align: right;
}

.apply {
  background-color: var(--primary);
  border: none;
  color: #fff;

  &:global(.disabled) {
    color: #9d999f;
    background-color: #f2f2f2;
  }
}

.cancel {
  cursor: pointer;
  color: var(--primary);
  margin-right: 20px;
}

.selectWrap {
  position: relative;
  display: inline-block;
  :global(.MuiSelect-icon) {
    display: none;
  }
}

.iconWrap {
  position: absolute;
  right: 0px;
  cursor: pointer;
  top: 50%;
  width: 30px;
  height: 30px;
  transform: translateY(-50%) scale(0.6);
  text-align: center;
  line-height: 30px;
  border-radius: 50%;

  &:hover {
    background-color: var(--hover-background);
  }
}

.menuNoPadding > :global(.MuiList-root) {
  padding: 0 !important;
}
