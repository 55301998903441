@import "src/styles/_table.scss";

.sortWrap {
  line-height: 8px;
  margin-left: var(--icon-distance);
  font-size: 7px;
}

.active {
  [class^="icon-"]::before {
    color: #000000;
  }
}
.tableContainer {
  table {
    table-layout: fixed;
  }
  td {
    word-break: break-word;
  }
}

:global(.#{$ping-right}, ),
:global(.#{$ping-left}) {
  overflow-x: auto;
  .absoluteHeader {
    visibility: visible !important;
  }
}

.tableHeadFixed {
  position: fixed;
  top: 0;
  box-sizing: border-box;
  z-index: -1;
  opacity: 0;
  overflow-x: auto;
  table {
    border: 1px solid var(--secondary);
  }
  border-top: none;
  -ms-overflow-style: none; /* 适用于Internet Explorer和Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none; /* 适用于Chrome、Safari和Opera */
  }
}

.headWrap {
  display: inline-flex;
  align-items: center;
}

.globalActionWrap {
  position: sticky;
  bottom: 0px;
  box-sizing: border-box;
  padding: 12px 0;
  z-index: 99;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--secondary);
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
}

.info {
  height: 60px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.globalBtn {
  border: 1px solid var(--color) !important;
  background-color: #ffffff !important;
  margin-right: 10px !important;
  line-height: 20px !important;
  & :global(.MuiLoadingButton-loadingIndicator) {
    position: static !important;
  }

  &:global(.disabled):not(:global(.loading)) {
    border-color: var(--secondary) !important;
  }
  &:hover {
    background-color: var(--hover-background) !important;
  }
}

:global(.multLineTdWrap) > div:not(:last-child) {
  margin-bottom: var(--table-info-distance);
}

.absoluteHeader {
  position: absolute;
  top: 0;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  left: 1px !important;
  right: 1px !important;
  z-index: 100 !important;
  opacity: 1 !important;
  border: none;
  visibility: hidden;
}
