.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 10px;
  width: 90%;
  margin: 0 auto;

  .img {
    width: 50%;
    max-width: 361px;
    min-width: 260px;

    & img {
      width: 100%;
    }
    margin-right: 46px;
  }
}

.tips {
  color: #6b6a64;
}

.title {
  margin-bottom: 16px;
}

.vertical {
  flex-wrap: wrap;

  .img {
    margin-bottom: 20px;
    & img {
      width: 60%;
    }
  }
  .title {
    margin-bottom: 8px;
  }
  .title,
  .img {
    text-align: center;
    margin-right: 0px;
  }
}
