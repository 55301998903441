.datesWrap {
  position: relative;
}

.content {
  display: flex;
  gap: 10px;
}

.left {
  flex: 0 0 auto;
  .row {
    width: 100%;
    justify-content: end;
  }
}

.right {
  position: relative;
  margin: 0 30px;
  flex: 1 1 auto;
}

.datesWrap {
  margin: 0 0 10px;
}

$rowHeight: 40px;
$num: 5;
$dashedColor: #ccc;

.bubbleCircle {
  width: 100%;
  margin-top: $rowHeight;
  height: calc($rowHeight * ($num - 1));
  position: relative;
}

.dashed {
  position: absolute;
  left: 0;
  right: 0;
}

.dashedRow {
  height: $rowHeight;
  position: relative;

  &.dashedLine {
    &::after {
      content: "";
      position: absolute;
      left: -20px;
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      border-top: 1px dashed $dashedColor;
    }
  }
}

.row {
  height: $rowHeight;
  display: flex;
  align-items: center;

  &:global(.sb) .circle {
    z-index: 2;
    position: relative;
  }
  &:global(.sbv) .circle {
    z-index: 3;
    position: relative;
  }

  &:global(.sbSbv) .circle {
    z-index: 4;
    position: relative;
  }
}

.circle {
  width: 28px;
  height: 28px;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid;
  font: var(--helper-font);
  text-align: center;
  line-height: 24px;
  border-color: var(--primary);
  color: var(--primary);
  background-color: #ffffff;
  cursor: pointer;

  $size: -5px;
  &.circleActive {
    box-shadow: 0px 0px 0px 2px var(--light-primary);
  }
}

.colLine {
  position: absolute;
  margin-left: -14px;

  height: 100%;

  ::after {
    content: "";
    position: absolute;
    visibility: hidden;
    top: 40px;
    bottom: 10px;
    left: 50%;
    width: 1px;
    border-left: 1px dashed $dashedColor;
    transform: translateX(-50%);
  }

  &:hover,
  &.active,
  &.isClicked {
    ::after {
      visibility: visible;
    }
    .circle {
      transform: scale(1.1);
      z-index: 5;
      background-color: var(--primary);
      color: #ffffff;
    }
  }

  &.isClicked:not(:hover):not(.active) {
    ::after {
      visibility: hidden;
    }
    .circle {
      z-index: 4;
    }
  }
}
