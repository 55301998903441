@import "../../var";

.adGroupWrap {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border-radius: $borderRadius;
  margin-right: $infoMarginRight;
  flex: 0 0 auto;
  text-align: center;
}

.download {
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 28px;
  height: 28px;
  line-height: 22px;
  border-radius: $borderRadius 0px $borderRadius 0px;

  :global(.icon-export) {
    &::before {
      color: #ffffff;
    }
  }
}

.mediumAsin {
  font-family: "Inter-Medium";
}

.group {
  margin-bottom: 8px;
}

.variantsNumWrap {
  border-radius: var(--border-radius);
  width: 147px;
}

.numInfo {
  width: 114px;
}

.variantsNum {
  border: 1px solid;
  border-color: var(--secondary) !important;
  color: var(--secondary-text-color);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.productAdTitle {
  color: var(--primary);
  border: 1px solid;
  box-sizing: border-box;
  background-color: var(--per04-primary);
  border-color: var(--primary) !important;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.targetVariantNumWrap,
.imgContainer {
  border: 1px solid var(--secondary);
  border-bottom: none;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}
.targetVariantNum {
  display: flex;
  width: 100% !important;
  justify-content: center;
  align-items: center;
  color: var(--secondary-text-color);
}

.imgWrap,
.targetVariantNum {
  padding: 12px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 147px;
  height: $topHeight;
}

.number {
  font: var(--menu-btn-font);
  margin-bottom: 8px;
}

.adContent {
  padding: 0 10px 12px;
  display: inline-flex;
}

.numInfo {
  width: 134px;

  margin-left: 8px;
}
