.tableWrap {
  margin-top: 20px;

  height: calc(max(45vh, 400px) + 55px);
  & > div:first-child {
    border: 1px solid var(--secondary);
    border-radius: var(--border-radius);
    max-height: max(45vh, 400px);
    overflow-y: auto;
  }

  & > div:nth-child(2) {
    margin-bottom: 0;
  }
}

.word {
  cursor: pointer;
  color: #0c5fff;
}

.actionsWrap {
  display: flex;
  justify-content: space-between;
}
